<script>
export default {
    created () {
        document.addEventListener('keydown', this.handleFocusNavigation)
    },
    beforeUnmount () {
        document.removeEventListener('keydown', this.handleFocusNavigation)
    },
    methods: {
        handleFocusNavigation (e) {

            if (e.code === 'Tab') { return e.preventDefault() }

            this.contextMenu.show = false

            let rowi = this.focusedCell[0]
            let coli = this.focusedCell[1]

            do {
                const keyCodeHandlers = {
                    'Enter': () => coli++,
                    'ArrowRight': () => coli++,
                    'ArrowLeft': () => coli--,
                    'ArrowUp': () => rowi--,
                    'ArrowDown': () => rowi++
                }
                if (!Object.keys(keyCodeHandlers).includes(e.code)) { return }
                
                e.preventDefault()
                
                if (typeof rowi !== 'number' || typeof coli !== 'number')
                    return this.focusCell(0, 0)

                // Make index move
                keyCodeHandlers[e.code]()
                
                // Make relative corrections that corresponding to the limits
                if (coli >= this.headers.length) {
                    rowi++
                    coli = 0
                }
                if (coli < 0) {
                    rowi--
                    coli = this.headers.length - 1
                }
                if (rowi >= this.items.length) { return }
                if (rowi < 0) { return }
                
            } while (!this.items[rowi][this.headers[coli].value].type.startsWith('input'))

            this.focusHandler(rowi, coli)
        },
        makeExtraScrollToFocused (newRowIndex, newColIndex, prevRowIndex, prevColIndex, floatingCellsLeft = 2) {

            const isMoveRight = newColIndex > prevColIndex
            const isMoveLeft = newColIndex < prevColIndex
            const isMoveDown = newRowIndex > prevRowIndex
            const isMoveUp = newRowIndex < prevRowIndex
            const viewportNode = this.$refs['report-detail-table-scroller']

            if (!viewportNode) return

            // Force to scroll left when focused cell moved to start
            // to prevent focused cell hid under floating column
            if (newColIndex <= 1)
                return viewportNode?.scrollTo(0, this.scrollTop)

            let scrollLeft = viewportNode.scrollLeft
            let scrollTop = viewportNode.scrollTop

            const viewportLeft = scrollLeft
            const viewportRight = viewportLeft + viewportNode.clientWidth
            const viewportTop = scrollTop
            const viewportBottom = viewportTop + viewportNode.clientHeight
            const focusedLeft = newColIndex * this.cellWidth
            const focusedRight = focusedLeft + this.cellWidth * 2
            const focusedTop = newRowIndex * this.cellHeight
            const focusedBottom = focusedTop + this.cellHeight

            if (isMoveRight && focusedRight > viewportRight)
                scrollLeft += this.cellWidth * 3

            else if (isMoveLeft && focusedLeft - (this.cellWidth * floatingCellsLeft) < viewportLeft)
                scrollLeft -= this.cellWidth * 3

            if (isMoveDown && focusedBottom + this.cellHeight > viewportBottom)
                scrollTop += this.cellHeight * 3
            else if (isMoveUp && focusedTop < viewportTop)
                scrollTop -= this.cellHeight * 3

            this.$refs['report-detail-table-scroller']?.scrollTo(scrollLeft, scrollTop)
        }
    }
}
</script>
<template>
    <v-select
        v-if="selectMode"
        ref="input"
        v-model="localValue"
        :items="items"
        :tabindex="tabindex"
        :disabled="readonly"
        :style="{ textAlign: align, maxWidth: '260px' }"
        @focus="$emit('focused')"
    />
    <input
        v-else
        :id="`#menu-activator${uid}`"
        ref="input"
        v-model="localValue"
        :title="hint"
        :tabindex="tabindex"
        :readonly="readonly"
        autocomplete="off"
        :style="{ textAlign: align }"
        @focus="$emit('focused')"
    />
</template>

<script>
export default {
    emits: ['update:model-value', 'focused'],
    props: {
        align: { type: String, default: 'center' },
        uid: { type: [String, Number], required: true },
        modelValue: { type: [String, Number], default: null },
        readonly: { type: Boolean, default: false },
        hint: { type: String, default: null },
        tabindex: { type: [String, Number], default: 0 },
        selectMode: { type: Boolean, default: false },
        items: { type: Array, default: null }
    },
    data: () => ({
        localValue: null
    }),
    watch: {
        modelValue (newVal) {
            if (newVal !== this.localValue)
                this.localValue = newVal
        },
        localValue (newVal) {
            if (newVal !== this.modelValue)
                this.$emit('update:model-value', newVal)
        }
    },
    created () {
        this.localValue = this.modelValue
    },
    methods: {
        focus () {
            this.$refs.input.focus()
        },
        selectСontent () {
            !this.readonly && this.$refs.input.select?.()
        }
    }
}
</script>

<style lang="scss" scoped>
input {
    width: calc(100% - 16px);
    &:focus {
        outline: none!important;
    }
}
</style>
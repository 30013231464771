<template>
    <div class="toolbar-default--component pa-0">
        <div>
            <div v-if="errorsCount" class="d-flex" :style="{ width: breakpoint && breakpoint.mdAndDown ? 'auto' : '30%' }">
            
                <span class="color-red mr-2">Найдено ошибок:</span>
                <span title="Показать ячейку с ошибкой" class="scroll-link noselect" @click="showCellWithError">{{ errorsCount }}</span>
            </div>
            <div
                v-else
                class="d-flex"
            >
                <!-- style="width: 30%" -->
                <div class="d-flex align-center mr-4">
                    <img :src="$const.icons.arrowKey" class="mr-1" />
                    <img :src="$const.icons.arrowKey" style="transform: scale(-1, 1)" class="mr-2"/>
                    <span class="color-grey-dark fs-11">Перемещение по клеткам</span>
                </div>

                <div class="d-flex align-center mr-4">
                    <img :src="$const.icons.enterKey" class="mr-2" />
                    <span class="color-grey-dark fs-11">Подтверждение + переход на клетку вперед</span>
                </div>

                <div class="d-flex align-center mr-4">
                    <span class="color-orange mr-2">Н</span>
                    <span class="color-grey-dark fs-11">Тема не проходилась</span>
                </div>

                <div class="d-flex align-center mr-4">
                    <span class="color-red mr-2">Х</span>
                    <span class="color-grey-dark fs-11">Не приступал к вопросу</span>
                </div>
            </div>
            <v-spacer />
            <div class="d-flex align-center">
                <v-btn 
                    :elevation="0" 
                    :disabled="disableButtons"
                    class="rounded-10 mr-2"
                    small
                    @click="$emit('signal:validate-inputs')"
                >
                    <span class="fs-12 fw-400">Проверить ошибки</span>
                </v-btn>
                <v-btn 
                    :elevation="0" 
                    :disabled="disableButtons"
                    class="rounded-10"
                    color="primary"
                    small
                    @click="$emit('signal:save', true)"
                >
                    <span class="fs-12 fw-400">Сохранить страницу</span>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    emits: [ 'signal:scroll-to-cell', 'signal:validate-inputs', 'signal:save' ],
    props: {
        errors: { type: Object, required: true },
        disableButtons: { type: Boolean, default: false }
    },
    data () {
        return {
            updateIndex: 0
        }
    },
    computed: {
        breakpoint () {
            return this.$vuetify.breakpoint
        },
        errorsCount () {
            this.updateIndex;
            if (!Object.keys(this.errors)) { return 0 }
            let count = 0
            for (const rowi in this.errors)
                count += Object.keys(this.errors[rowi]).length

            return count
        }
    },
    methods: {
        // Find cell indexed of first error and scroll to it
        showCellWithError () {
            for (const rowi in this.errors)
                for (const coli in this.errors[rowi])
                    if (this.errors[rowi][coli]) {
                        this.updateIndex++
                        return this.$emit('signal:scroll-to-cell', { rowi, coli, message: this.errors[rowi][coli] })
                    }
        },
    }
}
</script>

<style lang="scss" scoped>
.scroll-link {
    color: #1976D2;
    border-bottom: 1px dashed #1976D2;
    cursor: pointer;
}
.toolbar-default--component {
    container: toolbar / inline-size;

    & > div {

        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

@container toolbar (max-width: 850px) {
    
    .toolbar-default--component > div {

        flex-direction: column!important;
        align-items: start!important;

        div {
            margin-bottom: 4px;
        }
    }
}


</style>
<template>
    <v-container>
        <v-row>
            <v-col>
                <div class="fioko-report--detail">

                    <template v-if="formModel">

                        <v-progress-linear
                            v-if="!formModel || !formModel.length"
                            :size="50"
                            color="purple darken-2"
                            indeterminate
                        />
                        <template v-else>
                            <v-tabs v-model="currentTab" class="mb-2">  
                                <v-tab v-for="tab in tabs" :key="tab">{{ tab }}</v-tab>
                            </v-tabs>
                            <report-detail-table
                                v-show="currentTab === 0"
                                :key="0"
                                :model="classesModel"
                                :disable-tool-bar="saving"
                                @save="(payload) => savePage(payload, 'Классы', 'Классы')"
                            />
                            <report-detail-table
                                v-show="currentTab === 1"
                                :key="1"
                                :model="formModel"
                                :handbooks="handbooks"
                                :disable-tool-bar="saving"
                                @save="(payload) => savePage(payload, 'Протокол', 'Код')"
                            />
                        </template>
                    </template>
                    
                    <v-alert
                        v-else-if="error"
                        dense
                        type="error"
                        class="rounded-10"
                    >
                        {{ error }}
                    </v-alert>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ReportDetailTable from './ReportDetailTable'

export default {
    components: { ReportDetailTable },
    props: {
        reports: { type: Array, required: true },
        groupId: { type: Array, default: () => [] }
    },
    data () {
        return {
            saving: false,
            error: null,
            formModel: [],
            classesModel: [],
            handbooks: [],
            students: [],
            classesAndBooks: [],
            unknownBook: {
                name: null,
                author: null
            },
            availableBooks: [],
            currentTab: 1,
            tabs: ['Классы', 'Протокол']
        }
    },
    async created () {
        const { success: s1, data: d1, error: e1 } = await this.$store.dispatch(
                                                        'group/list',
                                                        {
                                                            filter: { id: { in: this.reports.map(r => r.group_id) } },
                                                            pagination: 0,
                                                            fields: 'student_id'
                                                        }
                                                    )
        if (!s1)
            throw new Error(e1)

        const user_ids = d1.items.map(item => item.student_id).flat()
        const { success: s2, data: d2, error: e2 } = await this.$store.dispatch('user/list', {
                                        filter: { id: { in: user_ids } },
                                        pagination: 0,
                                        fields: 'id,name'
                                    })
        if (!s2)
            throw new Error(e2)

        this.students = d2.items
        
        this.initFormModel()
    },
    methods: {
        clearUnknownBook () {
            this.unknownBook.name = null
            this.unknownBook.author = null
        },
        initFormModel () {
            for (const report of this.reports) {
                
                const model = JSON.parse(report.value)

                // Восстанавливаем состояние по учебникам
                const groupSheet = model.template.find(sheet => sheet.name === 'Классы')
                const protocolSheet = model.template.find(sheet => sheet.name === 'Протокол')

                const cellForBook = groupSheet?.cells?.find(cell => cell.code === 'C$2')

                this.availableBooks.push(...(cellForBook?.list || []))
                this.handbooks.push(...(model.handbooks || []))
                
                if (!this.formModel.length)
                    this.formModel.push(...protocolSheet.cells) // Модель таблицы Протокол
                else
                    protocolSheet.cells.forEach(cell => {
                        const modelCell = this.formModel.find(modelCell => modelCell.name === cell.name)
                        modelCell.values.unshift(...cell.values)
                    })

                if (!this.classesModel.length) {

                    this.classesModel.push(...groupSheet.cells) // Модель таблицы Классы
                    const classesCell = this.classesModel.find(cell => cell.code === 'C$1')
                    const bookCell = this.classesModel.find(cell => cell.code === 'C$2')
                    const authorCell = this.classesModel.find(cell => cell.code === 'C$3')
                    const bookTitleCell = this.classesModel.find(cell => cell.code === 'C$4')
                    
                    if (classesCell)
                        classesCell.infoType = true

                    if (bookCell)
                        bookCell.selectMode = true
                    
                    if (authorCell)
                        authorCell.emptyAvailableValues = true
                    
                    if (bookTitleCell)
                        bookTitleCell.emptyAvailableValues = true
                }
                else
                    groupSheet.cells.forEach(cell => {
                        const modelCell = this.classesModel.find(modelCell => modelCell.name === cell.name)
                        modelCell.values.unshift(...cell.values)
                    })
            }

            // Добавляем временные столбцы, которые не попадут в результат
            const temporaryColumns = []

            temporaryColumns.push({
                temporary: true,
                code: 'T$1',
                name: '№',
                values: this.students.map((s, i) => i + 1)
            })

            temporaryColumns.push({
                temporary: true,
                code: 'T$2',
                name: 'Ученик',
                values: this.students.map(s => s.name)
            })
            this.formModel.unshift(...temporaryColumns)
            // Временные столбцы - конец
        },
        async savePage (payload, sheetName, primaryColName) {
            try {
                this.saving = true

                for (const report of this.reports) {
                    

                    const model = JSON.parse(report.value)

                    const sheet = model.template.find(sheet => sheet.name === sheetName)

                    if (!sheet) {
                        console.error(`Can't find worksheet with name "${sheetName}"`)
                        continue
                    }

                    const primaryValues = sheet.cells.find(column => column.name === primaryColName)?.values

                    if (!primaryValues?.length) {
                        console.error(`Can't find column with name "${primaryColName}"`)
                        continue
                    }

                    const changedRowsIndexes = []
                    payload.model.find(column => column.name === primaryColName)?.values?.forEach((payloadPrimary, index) => {
                        if (primaryValues.includes(payloadPrimary))
                            changedRowsIndexes.push(index)
                    })

                    if (!changedRowsIndexes?.length) {
                        console.error('Can\'t find changed rows')
                        continue
                    }

                    for (const column of sheet.cells) {
                        const payloadColumn = payload.model.find(pcolumn => pcolumn.name === column.name)
                        if (!payloadColumn) {
                            console.error(`Can't find column with name ${column.name} in payload object`)
                            continue
                        }
                        const pickedValues = changedRowsIndexes.map(i => payloadColumn.values[i])
                        column.values = pickedValues
                    }
                    report.value = JSON.stringify(model)

                    const { success, error } = await this.$store.dispatch('fioko_report/update', report)

                    if (!success)
                        throw new Error(error)

                    this.$root.$emit('snack-bar', { text: 'Страница отчёта сохранена'})
                    this.$emit('updated', report)
                }
            } catch (e) {
                console.error(e)
                this.$root.$emit('snack-bar', { text: 'Ошибка: ' + (e.message || 'Не удалось сохранить страницу отчёта')})
            } finally {
                this.saving = false
            }
        }
    }
}
</script>
<template>
    <div class="report-detail-table--component">
        <div
            ref="report-detail-table-scroller"
            class="report-detail-table-scroller pos-rel"
            @scroll="onTableScroll"
        >
            <div class="table">
                <div
                    v-for="coli in headers.length"
                    :key="coli-1"
                    class="table-col"
                    :style="{
                        zIndex: [0, 1].includes(coli - 1) ? 1 : 0,
                        transform: [0, 1].includes(coli-1) ? `translateX(${scrollLeft}px)` : 'none'
                    }"
                >
                    <div
                        class="table-cell header-cell pos-rel px-2 py-2"
                        :class="{
                            'sibling-focused': focusedCell[1] === coli-1
                        }"
                        :style="{
                            zIndex: 1,
                            width: `${getCellWidth(coli-1)}px`,
                            transform: `translateY(${scrollTop}px)`,
                            'will-change': 'transform'
                        }"
                    >
                        <cell-header
                            :value="headers[coli-1].title"
                            :hint="headers[coli-1].hint"
                            :align="headers[coli-1].align"
                            class="w-100"
                        />
                    </div>
                    <div
                        v-for="rowi in items.length"
                        :key="rowi-1"
                        class="table-cell pos-rel"
                        :class="{
                            'has-error': errors[rowi-1] && errors[rowi-1][coli-1],
                            'focused': checkIsFocused(rowi-1, coli-1),
                            'sibling-focused': focusedCell[0] === rowi-1 && focusedCell[0] !== rowi && focusedCell[1] !== coli-1,
                            'first-cell': coli - 1 === 0
                        }"
                        :style="{
                            backgroundImage: !checkIsFocused(rowi-1, coli-1) && items[rowi-1][headers[coli-1].value].value === stringValues.skipped ? `url(${pattern1})` : '',
                            height: `${cellHeight}px`,
                            width: `${getCellWidth(coli-1)}px`
                        }"
                        @click="focusHandler(rowi-1, coli-1)"
                    >
                        <cell-input
                            :model-value="items[rowi-1][headers[coli-1].value].value"
                            :ref="`input_${rowi-1}_${coli-1}`"
                            :uid="`input_${rowi-1}_${coli-1}`"
                            :hint="items[rowi-1][headers[coli-1].value].hint"
                            :tabindex="calcTabIndex(rowi-1, coli-1)"
                            :readonly="!model[coli-1].selectMode && items[rowi-1][headers[coli-1].value].type !== 'input'"
                            :placeholder="items[rowi-1][headers[coli-1].value].placeholder"
                            :align="items[rowi-1][headers[coli-1].value].align"
                            :select-mode="model[coli-1].selectMode"
                            :items="model[coli-1].list"
                            class="ma-2"
                            :class="[
                                items[rowi-1][headers[coli-1].value].value === stringValues.notPassed && 'color-orange',
                                items[rowi-1][headers[coli-1].value].value === stringValues.skipped && 'color-red'
                            ]"
                            @focused="focusCell(rowi-1, coli-1)"
                            @update:model-value="($event) => onCellInputUpdate($event, rowi-1, coli-1)"
                        />
                    </div>
                </div>
                <v-list
                    v-if="contextMenu.show"
                    ref="contextMenu"
                    class="pos-abs elevation-5 rounded d-flex pa-0"
                    style="width: max-content!important; z-index: 1;"
                    :style="{ left: `${contextMenu.left}px`, top: `${contextMenu.top}px`}">
                    <v-list-item
                        v-for="(item, index) in availableInputValuesForFocusedCell"
                        :key="index"
                        :value="index"
                        @click="setValueFromContextMenu(item)"
                    >
                        <v-list-item-title
                        :class="[
                                    item === stringValues.notPassed && 'color-orange',
                                    item === stringValues.skipped && 'color-red'
                                ]"
                        >{{ item }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </div>
        <tool-bar
            :errors="errors"
            :disable-buttons="disableToolBar || processing"
            class="mt-6"
            @signal:scroll-to-cell="({ rowi, coli, message }) => onScrollToCellHandler(rowi, coli, message)"
            @signal:validate-inputs="onValidationButtonClicked"
            @signal:save="onSaveClicked"
        />
    </div>
</template>

<script>
import CellInput from './CellInput.vue'
import CellHeader from './CellHeader.vue'
import ToolBar from './ToolBar.vue'
import KeyNavigationMixin from './mixins/KeyNavigationMixin.vue'
import Pattern1 from '@/assets/img/pattern-1.png'

const stringValues = {
    absent: 'Отсутств.',
    notPassed: 'Н',
    skipped: 'Х'
}

export default {
    components: { CellInput, CellHeader, ToolBar },
    props: {
        model: { type: Array, required: true },
        handbooks: { type: Array, default: () => [] },
        disableToolBar: { type: Boolean, default: false }
    },
    mixins: [ KeyNavigationMixin ],
    data: () => ({
        focusedCell: [],
        scrollTop: 0,
        scrollLeft: 0,
        items: [],
        errors: {},
        processing: false,
        contextMenu: {
            show: false,
            left: 0,
            top: 0,
            width: 0,
            height: 48
        }
    }),
    computed: {
        stringValues: () => stringValues,
        pattern1: () => Pattern1,
        cellWidth: () => 94, // px
        cellHeight: () => 41, // px
        availableInputValuesForFocusedCell () {
            return this.getAvailableCellValues(...this.focusedCell)
        },
        headers () {
            const maxHeaderLength = 15
            return this.model.map(column => ({
                        title: column.name.length <= maxHeaderLength ? column.name : `${column.name.substring(0, maxHeaderLength)}...`,
                        value: column.code,
                        align: ['№', 'Ученик'].includes(column.name) ? 'left' : 'center',
                        hint: column.hint || (column.name.length <= maxHeaderLength ? null : column.name)
                    }))
        }
    },
    created () {
        this.contextMenu.width = 41 * this.getAvailableCellValues().length // 41 is width of single button in menu
        this.items = this.generateItems(this.model)
        // Восстанавливаем состояние для строк со значением "Отсутств."
        this.items.forEach(row => {
            const columnKeys = Object.keys(row)
            const allValues = columnKeys.map(key => row[key].value)
            if (allValues.includes(stringValues.absent))
                columnKeys.forEach(key => {
                    if (row[key].value !== stringValues.absent)
                        row[key].type = 'info'
                })
        })
        this.model.filter(col => col.type === 'TYPE_FORMULA').forEach(col => !col.values?.length && this.calculateFormulaOverColumn(col))
    },
    methods: {
        getCellWidth (colI) {
            const column = this.model[colI]

            const dictionary = {
                '№': this.cellWidth / 2,
                'Ученик': this.cellWidth * 2.5,
                'Основной учебник по предмету': this.cellWidth * 3
            }
            return dictionary[column?.name] || this.cellWidth
        },
        makeCellValueFancy (value) {
            const dictionary = {
                'не проид': stringValues.notPassed,
                'не пройд': stringValues.notPassed,
                'не проид.': stringValues.notPassed,
                'не пройд.': stringValues.notPassed,
                'X': stringValues.skipped,
                'отсутствовал': stringValues.absent
            }

            return dictionary[value] || value
        },
        convertFancyValueToReal (value, rowI, colI) {
            const dictionary = {
                [stringValues.notPassed]: [ 'не проид', 'не пройд', 'не проид.', 'не пройд.'],
                [stringValues.absent]: ['отсутствовал']
            }
            const alternativeValues = dictionary[value]

            if (!alternativeValues) return value
            const availableValues = this.getAvailableCellValues(rowI, colI, false)
            const match = availableValues.find(value => alternativeValues.includes(value))

            return match || value
        },
        getAvailableCellValues (rowI, colI, useFancySymbols = true) {

            const cellModel = this.model[colI]

            if (cellModel?.emptyAvailableValues || cellModel?.selectMode) return []

            if (cellModel?.type === 'TYPE_DROP_LIST' && cellModel?.list?.length)
                return cellModel.list.map(value => useFancySymbols ? this.makeCellValueFancy(value) : value)

            if (cellModel?.type_method === 'METHOD_DEP_LIST' && cellModel?.dep_list_values) {
                // Получаем индекс столбца модели, который указан, как тот от которого текущая ячейка зависит
                const dependentColumnIndex = this.model.findIndex(col => col.uuid === cellModel.dep_list_cell_uuid)

                if (dependentColumnIndex > -1) {
                    // Получаем ячейку в соответствии текущей строкой и столбцом от которого зависит текущий столбец
                    const cell = this.items[rowI][this.model[dependentColumnIndex].code]
                    // Получаем список разрешенных значений для текущей ячейки в зависимости от значения введенного в ячейки от которой зависит текущая
                    if (cell?.value)
                        return cellModel?.dep_list_values[cell.value]?.map(value => useFancySymbols ? this.makeCellValueFancy(value) : value)
                }
            }

            if (
                cellModel?.type === 'TYPE_LIST_HANDBOOK' &&
                this.handbooks.findIndex(h => h.id === cellModel?.list_handbook?.id) > -1
            )
                return this.handbooks.find(h => h.id === cellModel.list_handbook.id).value.map(value => useFancySymbols ? this.makeCellValueFancy(value) : value)
            
            return ['0', '1', '2', '3', '4', '5', stringValues.skipped, stringValues.notPassed]
        },
        generateItems (model) {
            const getCellTypeByColumn = (col) => {
                const infoTypeFields = ['Код', 'Итого баллов', '№', 'Ученик']

                if (col.infoType || infoTypeFields.includes(col.name))
                    return 'info'

                if (col.type === 'TYPE_DROP_LIST' || col.type_method === 'METHOD_DEP_LIST')
                    return 'input-dropdown'

                return 'input'
            }
            return model[0].values.map((c, rowI) => {
                const row = {}
                model.forEach(column => {
                    const initialType = getCellTypeByColumn(column)
                    row[column.code] = {
                        initialType,
                        type: initialType,
                        value: this.makeCellValueFancy(column.values[rowI]),
                        align: ['№', 'Ученик'].includes(column.name) ? 'left' : 'center',
                        required: column.required,
                        hint: column.name === 'Ученик' ? column.values[rowI] : null
                    }
                })
                
                return row
            })
        },
        onTableScroll (event) {
            this.contextMenu.show = false
            this.scrollTop = event.target.scrollTop
            this.scrollLeft = event.target.scrollLeft
        },
        checkIsFocused (rowi, coli) {
            return this.focusedCell[0] === rowi && this.focusedCell[1] === coli
        },
        calcTabIndex (rowi, coli) {
            return (rowi * this.headers.length) + coli
        },
        focusCell (rowi, coli, forceProcess = false) {
            if (typeof rowi !== 'number')
                rowi = null
            if (typeof coli !== 'number')
                coli = null
            // Prevent processing if given data equals current focus position.
            // `forceProcess` variable needs to controll this condition activity.
            if (rowi === this.focusedCell[0] && coli === this.focusedCell[1] && forceProcess === false) { return }

            // Clear error on focused cell
            if (this.errors?.[rowi]?.[coli])
                delete this.errors[rowi][coli]

            this.focusedCell = [rowi, coli]
            this.$refs[`input_${rowi}_${coli}`]?.[0]?.focus()
            this.$refs[`input_${rowi}_${coli}`]?.[0]?.selectСontent()
        },
        onCellInputUpdate (value, rowi, coli) {

            this.items[rowi][this.headers[coli].value].value = value

            this.model.filter(col => col.type === 'TYPE_FORMULA').forEach(col => this.calculateFormulaOverColumn(col, rowi))
        },
        calculateFormulaOverColumn (column, rowI = null) {
            // Получаем каждый идентификатор из строки формулы и сохраняем результат в массив
            const columnIds = this.getUuidFromFormula(column.formula)

            const calculateFormulaOnRow = (rowI) => {
                let expression = column.formula

                columnIds.forEach(uuid => {
                    const cellValue = parseInt(this.getCellValueByUUID(uuid, rowI)) || 0
                    // Делаем автозамену идентификаторов на найденные значения для каждой строки
                    expression = expression.replaceAll(uuid, cellValue)
                })
                // Удалить лишние символы, которые обрамляли идентификаторы
                expression = expression.replaceAll('[#$_', '').replaceAll('#]', '')
                // Выполняем eval для полученной строки
                this.items[rowI][column.code].value = eval(expression)
            }

            if (rowI === null) {
                // Запускаем обход каждой строки
                this.items.forEach((row, _rowI) => calculateFormulaOnRow(_rowI))
            } else {
                calculateFormulaOnRow(rowI)
            }
        },
        getUuidFromFormula (formula) {
            const uuidArray = []
            const regex = /\[#\$_(.*?)#\]/gm;
            let m;
            while ((m = regex.exec(formula)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex)
                    regex.lastIndex++;
                
                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    // console.log(`Found match, group ${groupIndex}: ${match}`);
                    if (groupIndex === 1)
                    uuidArray.push(match)
                });
            }
            return uuidArray
        },
        getCellValueByUUID (columnUuid, rowI) {
            const column = this.model.find(col => col.uuid === columnUuid)

            if (!column) { return null }
            return this.items[rowI][column.code]?.value
        },
        calculateCellRequiredField (rowI, colI) {

            if (typeof rowI !== 'number' || typeof colI !== 'number') { return }

            const cell = this.items[rowI][this.headers[colI].value]
            const column = this.model[colI]

            if (!column.dependence?.type || !column.dependence.list) { return }
            
            if (!Array.isArray(column.dependence.list)) {
                column.dependence.list = [ column.dependence.list ]
            }

            // Определяем НЕ выполнение условий в cellCondition.cell_uuid
            const conditionResultsByEachCell = column.dependence.list.map(cellCondition => {

                const dominantCellValue = this.getCellValueByUUID(cellCondition.cell_uuid, rowI)
                return !cellCondition.list.includes(dominantCellValue)
            })

            const filterMethod = column.dependence.type === 'TYPE_EVERY' ? 'every' : 'some'
                
            cell.required = conditionResultsByEachCell[filterMethod](value => value === true) ? !column.required : column.required
        },
        validateInputs () {
            if (this.processing) { return }

            this.contextMenu.show = false
            this.processing = true
            this.errors = {}
            let foundErrors = false

            
            for (const rowi in this.items) {
                for (const coli in this.headers) {

                    const cell = this.items[rowi][this.headers[coli].value]
                    
                    this.calculateCellRequiredField(parseInt(rowi), parseInt(coli))
                    
                    if (// Делаем проверку только если
                        !cell.type.startsWith('input') || // у ячейки один из типов поля ввода
                        !cell.required // ячейка является обязательной для заполнения
                    ) { continue }

                    const validate = (rowi, coli, cell) => this.model[coli].selectMode ?
                                                                            this.model[coli].list.includes(cell.value) :
                                                                            this.model[coli].emptyAvailableValues ?
                                                                                !!cell.value :
                                                                                this.getAvailableCellValues(rowi, coli).map(value => value?.toString())?.includes(`${cell.value}`)

                    if (!validate(rowi, coli, cell)) {
                        if (!this.errors[rowi])
                        this.errors[rowi] = {}
                    
                        foundErrors = true
                        this.errors[rowi][coli] = cell.value ? 'Содержимое ячейки не входит в диапазон допустимых значений' : 'Ячейка не заполнена'
                    }
                }
            }
            this.processing = false
            return !foundErrors
        },
        // Scroll to specified cell
        onScrollToCellHandler (rowi, coli, message) {
            rowi = parseInt(rowi)
            coli = parseInt(coli)

            this.focusHandler(rowi, coli)

            if (message)
                this.$root.$emit('snack-bar', { text: `Проблема: ${message}` })
        },
        showContextMenu (rowi, coli) {
            
            this.contextMenu.show = true

            const viewportNode = this.$refs['report-detail-table-scroller']
            const horizontalScrollBarHeight = 10 // px

            if (!viewportNode) return

            const leftOffset = this.model.filter((col, colI) => colI < coli).reduce((prev, col, colI) => this.getCellWidth(colI) + prev, 0)

            this.contextMenu.top = (rowi * this.cellHeight) + (this.cellHeight * 2) - horizontalScrollBarHeight
            this.contextMenu.left = leftOffset

            if (this.contextMenu.top + this.contextMenu.height > viewportNode.scrollTop + viewportNode.clientHeight)
                this.contextMenu.top -= this.contextMenu.height * 2

            if (this.contextMenu.left + this.contextMenu.width > viewportNode.scrollLeft + viewportNode.clientWidth) {

                this.contextMenu.left -= this.contextMenu.width - this.cellWidth
                // Подравниваем меню после инициализации domElement в соответсвии с его шириной
                this.$nextTick().then(() => {
                    this.contextMenu.left = leftOffset - this.$refs.contextMenu.$el.clientWidth + this.cellWidth
                })
            }
        },
        focusHandler (rowi, coli, ) {

            const prevRowIndex = this.focusedCell[0]
            const prevColIndex = this.focusedCell[1]
            const beforeFocusScrollLeft = this.$refs['report-detail-table-scroller']?.scrollLeft || 0
            const beforeFocusScrollTop = this.$refs['report-detail-table-scroller']?.scrollTop || 0

            this.focusCell(rowi, coli, true)
            this.makeExtraScrollToFocused(rowi, coli, prevRowIndex, prevColIndex)

            const afterFocusScrollLeft = this.$refs['report-detail-table-scroller']?.scrollLeft || 0
            const afterFocusScrollTop = this.$refs['report-detail-table-scroller']?.scrollTop || 0
            
            const isNextCellHasInputType = this.items[rowi][this.headers[coli].value].type.startsWith('input')
            const isExtraScrollWasInvoked = beforeFocusScrollLeft !== afterFocusScrollLeft || beforeFocusScrollTop !== afterFocusScrollTop

            
            if (isNextCellHasInputType)
                isExtraScrollWasInvoked ?
                    setTimeout(() => this.showContextMenu(rowi, coli), 50) :
                    this.showContextMenu(rowi, coli)
            else
                this.contextMenu.show = false
        },
        async setValueFromContextMenu (value) {
            const rowi = this.focusedCell[0]
            const coli = this.focusedCell[1]

            // Устанавливаем значение всем ячейкам текущего столбца
            if (value === stringValues.notPassed && confirm('Отметить указанное значение по всему столбцу?')) {
                for (const _rowi in this.items) {

                    this.items[_rowi][this.headers[coli].value].value = value
                    
                    if (this.errors[_rowi]?.[coli])
                            this.errors[_rowi][coli] = null
                }
            }
            else if (value === stringValues.absent) {
                for (const _coli in this.headers) {

                    if (this.items[rowi][this.headers[_coli].value].type === 'info') continue
                    
                    const availableOptions = this.getAvailableCellValues(rowi, _coli)

                    if (!availableOptions.includes(stringValues.absent)) {
                        // Обнуляем значения, если были
                        this.items[rowi][this.headers[_coli].value].value = null
                        this.items[rowi][this.headers[_coli].value].type = 'info'
                        if (this.errors[rowi]?.[_coli])
                            this.errors[rowi][_coli] = null
                    }
                }
            } else {
                // Если предыдущее значение было 'Отсутств.', то восстанавливаем прежний тип ячеек
                if (this.items[rowi][this.headers[coli].value].value === stringValues.absent)
                    for (const _coli in this.headers) {
                        this.items[rowi][this.headers[_coli].value].type = this.items[rowi][this.headers[_coli].value].initialType
                    }
                
                // Вставляем значение
                this.items[rowi][this.headers[coli].value].value = value
            }

            this.contextMenu.show = false
            this.onCellInputUpdate(value, rowi, coli)
        },
        async onSaveClicked () {
            if (this.processing) { return false }


            if (!this.validateInputs()) {
                // TODO: Сделать глобальный снекбар, как в прилоежнии
                this.$root.$emit('snack-bar', { text: 'Невозможно сохранить отчет с ошибками'})
                return false
            }

            this.processing = true
            const model = _.cloneDeep(this.model)
            // Передаем значения из items в model
            this.items.forEach((row, rowI) => {
                model.forEach((col, colI) => {
                    col.values[rowI] = this.convertFancyValueToReal(row[col.code].value, rowI, colI)
                })
            })

            this.$emit('save', { status: 'done', model })
            this.processing = false
        },
        onValidationButtonClicked () {
            const validated = this.validateInputs()
            
            if (validated)
                this.$root.$emit('snack-bar', { text: 'Ошибок не найдено'})
            else {
                let count = 0
                for (const rowi in this.errors)
                    count += Object.keys(this.errors[rowi]).length

                this.$root.$emit('snack-bar', { text: `Найдено ошибок: ${count} `})
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>

.report-detail-table-scroller {
    overflow: scroll;
    height: 50vh;

    // Scrollbar
    &::-webkit-scrollbar {
        width: 10px;                  /* ширина scrollbar */
        height: 10px;                  /* ширина scrollbar */
    }
    &::-webkit-scrollbar-track {
        background: white;         /* цвет дорожки */
    }
    &::-webkit-scrollbar-thumb {
        background-color: #04194d30;     /* цвет плашки */
        border-radius: 12px;          /* закругления плашки */
        border: 3px solid white;   /* padding вокруг плашки */
    }
}

.table {
    white-space: nowrap;
    display: flex;
    // border: 1px solid rgba(17, 17, 17, 0.12);
    border-left: none;
    border-radius: 8px;
}

.sibling-focused {
    background-color: #d6e8fa!important;
    
    &.first-cell {
        border-left: 2px solid rgba(0, 113, 227, 1)!important;
    }
}

.table-col {
    // Заглушка, которая скрывает артефакты возле загкругления левого верхнего угла таблицы
    &:first-child .header-cell::after {
        content: "";
        position: absolute;
        width: 5px;
        height: 11px;
        background-color: white;
        top: -3px;
        left: -4px;
        transform: rotate(45deg);
    }

    

    .table-cell {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        justify-content: center;
        white-space: break-spaces;
        border-right: 1px solid rgba(17, 17, 17, 0.12);
        border-bottom: 1px solid rgba(17, 17, 17, 0.12);
        line-height: 1em;
        background-color: #FFFFFF;
        background-repeat: repeat;

        &.first-cell {
            border-left: 1px solid rgba(17, 17, 17, 0.12);
        }

        &.header-cell {
            height: 31px;
            background-color: #EBEDF1;
            font-family: 'Roboto';
            font-weight: 600;
            font-size: 14px;
            color: #191B27AD;

            // Заглушка, которая скрывает артефакты над таблицей
            &::before {
                content: "";
                position: absolute;
                width: calc(100% + 4px);
                height: 5px;
                background-color: white;
                top: -5px;
                left: -2px;
            }
        }

        &.focused {
            border: 2px solid rgba(0, 113, 227, 1)
        }

        &.has-error {
            border-bottom: 1px solid #e94141;
            color: #e94141;
            background: #fbe0e0!important;
        }
    }

    &:first-child {
        .table-cell {

            &:first-child {
                border-top-left-radius: 8px;
            }
            &:last-child {
                border-bottom-left-radius: 8px;
            }
        }
    }

    &:last-child {
        .table-cell {

            &:first-child {
                border-top-right-radius: 8px;
            }
            &:last-child {
                border-bottom-right-radius: 8px;
            }
        }
    }

    &:nth-child(2) {
        box-shadow: 6px 0px 8px #00000012;
    }
}
</style>
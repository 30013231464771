<template>
    <div :style="{ textAlign: align }">
        <v-icon
            v-if="hint"
            :title="hint"
            size="small"
            class="pos-abs cursor-pointer"
            style="top: 2px; left: 0px; z-index: 9;"
        >mdi-information-outline</v-icon>
        <b>{{ value }}</b>
    </div>
</template>

<script>

export default {
    props: {
        value: { type: [String, Number], default: null },
        hint: { type: String, default: null },
        align: { type: String, default: 'center' }
    }
}
</script>
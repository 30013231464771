<template>
    <v-form>
        <v-progress-linear
            v-if="loading.reports"
            color="purple darken-2"
            indeterminate
        />
        <v-alert
            v-else-if="error"
            dense
            type="error"
        >
            {{ error }}
        </v-alert>

        <div class="d-flex justify-start align-center py-0 mb-5">
            <span class="d-flex align-center mr-5"><v-subheader>Название</v-subheader> {{ model.name }}</span>
            <span class="d-flex align-center mr-5"><v-subheader>Начало</v-subheader> {{ getReadableDate(model.start_at) }}</span>
            <span class="d-flex align-center mr-5"><v-subheader>Завершение</v-subheader> {{ getReadableDate(model.end_at) }}</span>
            <span class="d-flex align-center mr-5"><v-subheader>Статус</v-subheader> <v-chip>{{ model.status_formatted }}</v-chip></span>
        </div>

        
        <report-form
            v-for="report in reports"
            :key="report.id"
            :report="report"
            :teachers="teachers"
            :groups="groups"
            :selected="selectedReportIds.includes(report.id)"
            :disabled="loading.reports"
            class="mb-5"
            @updated="() => snackbar = true"
            @removed="fetchReports"
            @update:select="onReportSelectChange($event, report.id)"
        />

        <v-subheader v-if="!reports.length && !loading.reports">По данной форме сбора данных не назначены отчёты на преподавателей.</v-subheader>
               
        <div v-if="isCanEditFiokoDataCollectionSection" class="d-flex align-center">
            <v-btn
                v-if="!loading.reports"
                text color="purple"
                :disabled="loading.creatingReport || loading.dataCollections || loading.creatingResult || loading.forms || loading.reports"
                @click="createReport"
            >+ Назначить</v-btn>

            <v-spacer />

                
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :disabled="loading.creatingResult || !(selectedReportIds.length && allSelectedReportsHasDoneStatus)"
                        color="purple"
                        text
                        v-bind="attrs"
                        v-on="on"
                    >
                        Экспорт
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(item, index) in exportWays"
                        :key="index"
                        @click="item.action"
                    >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn
                :disabled="loading.creatingResult || !(selectedReportIds.length && allSelectedReportsHasDoneStatus)"
                text color="primary"
                @click="dialogs.preview = true"
            >Предпросмотр</v-btn>

            <v-btn
                :loading="loading.creatingResult"
                :disabled="!(selectedReportIds.length && allSelectedReportsHasDoneStatus)"
                text color="primary"
                @click="createResult(selectedReportIds)"
            >Сформировать отчёт</v-btn>

            <v-btn
                text
                color="primary"
                :disabled="!linkToGeneratedFile || loading.creatingResult"
                :loading="loading.downloadResult"
                @click="downloadGeneratedFile"
            >Скачать отчёт</v-btn>
        </div>


        <v-snackbar v-model="snackbar" :timeout="1000">
            Изменения успешно сохранены!
        </v-snackbar>

        <v-divider class="my-4" />

        <action-buttons hide-save-btn @back="back" />

        <v-alert v-if="error" type="error">
            {{ error }}
        </v-alert>

        <v-dialog v-if="isCanEditFiokoDataCollectionSection" v-model="dialogs.preview" persistent max-width="1300px">
           
            <v-card v-if="dialogs.preview" class="pa-2">
                <v-card-actions class="pa-0">
                    <v-spacer></v-spacer>
                    <v-btn outlined small @click.prevent.stop="closePreviewHandler">
                        Закрыть
                    </v-btn>
                </v-card-actions>
                <v-card-text class="pa-0">
                    <preview
                        :reports="selectedReports"
                        @updated="onReportUpdatedInPreview"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
import { saveDataAsFile } from '@/helpers/File'
import ActionButtons from '@/components/crud/ActionButtons.vue'
import { WORKFLOW_SERVER } from '@/servers'
import ReportForm from './ReportForm.vue'
import Preview from './Preview.vue'
import isUserCanMixin from '@/mixins/isUserCanMixin'
import FiokoDataCollectionMixin from '@/mixins/FiokoDataCollectionMixin'

export default {
    components: { ActionButtons, ReportForm, Preview },
    mixins: [isUserCanMixin, FiokoDataCollectionMixin],
    props: {
        model: { type: Object }
    },
    data () {
        return {
            snackbar: false,
            error: null,
            loading: {
                dataCollections: false,
                reports: false,
                creatingReport: false,
                creatingResult: false,
                downloadResult: false
            },
            dialogs: {
                preview: false
            },
            selectedReportIds: [],
            reports: [],
            groups: [],
            teachers: [],
            generatedFilePath: null
        }
    },
    async created () {
        this.loading.reports = true
        const results = await Promise.all([this.fetchGroups(), this.fetchTeachers()])
        this.loading.reports = false

        const error = results.find(result => typeof result === 'string')
        if (error) {
            this.error = error
            return
        }

        await this.fetchReports()
    },
    computed: {
        exportWays () {
            return [
                {
                    title: 'Как JSON',
                    action: this.exportToJsonHandler
                },
                {
                    title: 'Как XML',
                    action: this.exportToXmlHandler
                },
            ]
        },
        linkToGeneratedFile () {
            const filePath = this.generatedFilePath || this.model.generated_file_path
            return filePath ? `${WORKFLOW_SERVER}/${filePath}` : null
        },
        jsonForm () {

            const jsonForm = typeof this.model.json_template === 'string' ? JSON.parse(this.model.json_template) : this.model.json_template

            return JSON.stringify({
                template: jsonForm.data.template,
                handbooks: typeof this.model.handbooks === 'string' ? JSON.parse(this.model.handbooks) : this.model.handbooks
            })
        },
        allSelectedReportsHasDoneStatus () {
            return this.selectedReports.length && this.selectedReports.every(report => report.status === 'done')
        },
        selectedReports () {
            return this.reports.filter(report => this.selectedReportIds.includes(report.id))
        }
    },
    methods: {
        back () {
            return this.$emit('back', 1);
        },
        exportToJsonHandler () {
            
            if (!(this.selectedReportIds.length && this.allSelectedReportsHasDoneStatus)) { return false }

            const reports = this.reports.filter(r => this.selectedReportIds.includes(r.id))
            this.exportToJson(reports, this.model.name)
        },
        exportToXmlHandler () {

            if (!(this.selectedReportIds.length && this.allSelectedReportsHasDoneStatus)) { return false }

            const reports = this.reports.filter(r => this.selectedReportIds.includes(r.id))
            this.exportToXml(reports, this.model.name)
        },
        async fetchGroups () {
            try {
                const { data } = await this.$store.dispatch('group/list', {
                            pagination: 0,
                            fields: 'id,name'
                        });
                this.groups = data.items?.map(item => ({
                    text: item.name,
                    value: item.id
                }))
                return true
            } catch (e) {
                console.error(e)
                return e.message || 'Неизвестная ошибка загрузки данных'
            }
        },
        async fetchTeachers () {
            try {
                const { data } = await this.$store.dispatch('user/list', {
                            pagination: 0,
                            filter: { active: 1, role: 'teacher' },
                            fields: 'id,name'
                        });
                this.teachers = data.items?.map(item => ({
                    text: item.name,
                    value: item.id
                }))
                return true
            } catch (e) {
                console.error(e)
                return e.message || 'Неизвестная ошибка загрузки данных'
            }
        },
        async fetchReports () {
            
            try {
                this.reports = []
                this.error = null
                this.loading.reports = true
                const { data: reports } = await this.$store.dispatch('fioko_report/list', {
                    fields: 'value,teacher_id,group_id,status,subject',
                    filter: { data_collection_id: this.model.data_collection_id }
                })
                
                this.reports = reports?.items || []
            } catch (e) {
                console.error(e)
                this.error = e.message || 'Неизвестная ошибка загрузки данных'
            } finally {
                this.loading.reports = false
            }
        },
        getReadableDate (timestamp) {
            return this.$moment(timestamp, 'X').format('DD.MM.YYYY')
        },
        async createReport () {
            
            this.loading.creatingReport = true
            try {
                const newReport = {
                    data_collection_id: this.model.data_collection_id,
                    form_template_id: this.model.form_template_id,
                    teacher_id: null,
                    group_id: null,
                    value: this.jsonForm,
                    status: 'waiting'
                }

                await this.$store.dispatch('fioko_report/create', newReport)
                this.fetchReports()
            } catch (e) {
                console.error(e)
                this.error = e.message || 'Неизвестная ошибка загрузки данных'
            } finally {
                this.loading.creatingReport = false
            }
        },
        onReportSelectChange ({ value, reportId }) {
            if (value)
                this.selectedReportIds.push(reportId)
            else
                this.selectedReportIds = this.selectedReportIds.filter(id => reportId !== id)
        },
        async createResult (reportIds) {
            try {
                this.error = null
                this.loading.creatingResult = true

                const { success, data, error } = await this.$store.dispatch('fioko_data_collection/combineReports', {reportIds, dataCollectionId: this.model.data_collection_id})
                
                if (!success)
                    throw new Error(error)
                
                this.generatedFilePath = data.path
                this.selectedReportIds = []
            } catch (e) {
                console.error(e)
                this.error = e.message
            } finally {

                this.loading.creatingResult = false
            }
        },
        async downloadGeneratedFile () {
            try {
                this.loading.downloadResult = true
                const data = await this.$store.dispatch('fioko_data_collection/downloadGeneratedFile', this.model.data_collection_id)
                saveDataAsFile(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', `${this.model.name}.xlsx`)
            } catch (e) {
                console.error(e)
            } finally {
                this.loading.downloadResult = false
            }
        },
        onReportUpdatedInPreview (report) {
            const reportIndex = this.reports.findIndex(r => r.id === report.id)
            this.reports[reportIndex] = report
        },
        closePreviewHandler () {
            const confirmed = confirm('Все несохраненные изменения будут утеряны. Вы уверены?')
            
            if (!confirmed) return

            this.dialogs.preview = false
        }
    }
}
</script>
<template>
    <v-card class="d-flex flex-column align-start pa-4">
    
        <div class="d-flex align-center">
            <v-checkbox
                v-if="isCanEditFiokoDataCollectionSection"
                v-model="localSelected"
                @change="$event => $emit('update:select', { value: !!$event, reportId: report.id })"
            ></v-checkbox>
            <h4>Отчет</h4>
        </div>

        <span class="d-flex align-center">
            <v-subheader>Статус</v-subheader>
            <v-chip :color="reportStatuses[report.status].color">{{ reportStatuses[report.status].text }}</v-chip>
            <v-btn
                v-if="report.status === 'done' && isCanEditFiokoDataCollectionSection"
                class="ml-2"
                small
                elevation="0"
                rounded
                @click="returnToRevision(report)"
            >
                <v-icon dark class="mr-2">mdi-reload</v-icon> Вернуть на доработку
            </v-btn>
        </span>

        <div class="d-flex align-center">
            <span class="d-flex align-center mr-8">
                <v-subheader>Предмет</v-subheader>
                <v-select
                    v-model="report.subject"
                    :items="subjects"
                    :menu-props="{ maxHeight: '500' }"
                    :disabled="!isCanEditFiokoDataCollectionSection || totalDisabled || report.status !== 'waiting'"
                    color="purple darken-2"
                    @change="updateReport(report)"
                />
            </span>
            <span v-if="teachers" class="d-flex align-center mr-8">
                <v-subheader>Ответственный учитель</v-subheader>
                <v-select
                    v-model="report.teacher_id"
                    :items="teachers"
                    :menu-props="{ maxHeight: '500' }"
                    :disabled="!isCanEditFiokoDataCollectionSection || totalDisabled || report.status !== 'waiting'"
                    color="purple darken-2"
                    @change="updateReport(report)"
                />
            </span>
            <span v-if="groups" class="d-flex align-center mr-8">
                <v-subheader>Данные для класса</v-subheader>
                <v-select
                    v-model="report.group_id"
                    :items="groups"
                    :menu-props="{ maxHeight: '500' }"
                    :disabled="!isCanEditFiokoDataCollectionSection || totalDisabled || report.status !== 'waiting'"
                    style="max-width: 100px"
                    color="purple darken-2"
                    @change="updateReport(report)"
                />
            </span>
        </div>
        
        <div v-if="isCanEditFiokoDataCollectionSection && [null, 'waiting'].includes(report.status)" class="d-flex align-center justify-end w-100">
            <v-btn
                text
                small
                color="red"
                class="mr-2"
                :disabled="totalDisabled"
                @click="dialogs.reportToRemove = report.id"
            >
                <v-icon>mdi-trash-can</v-icon>
                Удалить
            </v-btn>

            <confirm-action-with-pass-dialog
                :value="!!dialogs.reportToRemove"
                title="Для подтверждения удаления введите пароль от текущей учетной записи"
                :action="() => removeReport(dialogs.reportToRemove)"
                @change="dialogs.reportToRemove = null"
            />
        </div>
    </v-card>
</template>

<script>
import { reportStatuses } from './dictionaries'
import subjects from '@/subjects'
import isUserCanMixin from '@/mixins/isUserCanMixin'
import ConfirmActionWithPassDialog from '@/components/template/ConfirmActionWithPassDialog.vue'

export default {
    mixins: [isUserCanMixin],
    components: { ConfirmActionWithPassDialog },
    props: {
        report: { type: Object, required: true },
        selected: { type: Boolean, default: false },
        teachers: { type: Array, default: null },
        groups: { type: Array, default: null },
        disabled: { type: Boolean, default: false }
    },
    data () {
        return {
            localSelected: false,
            localDisabled: false,
            dialogs: {
                reportToRemove: null
            }
        }
    },
    computed: {
        reportStatuses () { return reportStatuses },
        subjects () { return subjects },
        totalDisabled () { return this.disabled || this.localDisabled }
    },
    watch: {
        selected: {
            immediate: true,
            handler () {
                this.localSelected = this.selected
            }
        }
    },
    methods: {
        async returnToRevision (report) {
            report.status = 'processing'
            this.updateReport(report)
        },
        async updateReport (report) {
            try {
                this.localDisabled = true
                await this.$store.dispatch('fioko_report/update', report)
                this.$emit('updated', report)
            } catch (e) {
                console.error(e)
            }
            finally {
                this.localDisabled = false
            }
        },
        async removeReport (reportId) {
            try {
                this.localDisabled = true
                await this.$store.dispatch('fioko_report/delete', { id: reportId })
                this.$emit('removed', reportId)
            } catch (e) {
                throw new Error(e)
            } finally {
                this.localDisabled = false
            }
        },
    }
}
</script>